import {
  AppBar,
  Divider,
  Grid,
  Toolbar,
  Typography,
  Box,
  ClickAwayListener,
  IconButton,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import IconFromSvg from '../../atoms/IconFromSvg';
import messages from '../../../core-utils/messages';
import InformationSvg from '../../../../public/assets/information.svg';
import CloseIcon from '../../../../public/assets/close-dark.svg';
import Button from '../../atoms/Button';
import TextField from '../../atoms/TextField';
import { IPolicyBuilderHeaderProps } from '../../../core-utils/constants';
import policyBuilderMessages from '../../../core-utils/messages/policyBuilder';
import PolicyHelpTooltip from '../../molecules/PolicyHelpTooltip';
import PolicyIconsHelpTooltip from '../../molecules/PolicyIconsHelpTooltip';
import { useAppSelector } from '../../../Redux/Store/store';
import { RootStateOrAny } from 'react-redux';
import { DEFAULT_APP_ICON} from 'src/core-utils/Helper/helper';

const StyledPolicyHeader = styled(AppBar)({
  backgroundColor: theme.palette.common.white,
  height: theme.spacing(16.25),
  width: '100%',
  paddingLeft: theme.spacing(3.25),
  color: theme.palette.common.black,
});

const StyledTextField = styled(TextField)({
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.palette.grey[500],
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.grey[500],
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.grey[500],
    },
    '&.Mui-error fieldset': {
      borderColor: `${theme.palette.error.main} !important`,
    },
  },
});

const PolicyBuilderHeader = ({
  policyName,
  target,
  handleSave,
  handlePolicyNameUpdate,
  handleTestOrEdit,
  onCloseClick,
  isSaveDisabled,
  editable,
  searchData,
  savedData,
  isSaving
}: IPolicyBuilderHeaderProps) => {
  const [isEditable, setIsEditable] = useState(editable);
  const [policyBuilderName, setPolicyBuilderName] = useState(policyName);
  const [policyBuilderTarget, setPolicyBuilderTarget] = useState(target);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSaveEnabled, setSaveEnabled] = useState<boolean>(true);
  const headerData = useAppSelector((state: RootStateOrAny) => state.header);

  const handleEditable = () => {
    setIsEditable(true);
  };
  const handlePolicyNameChange = (event: any) => {
    if (event.target.value === '') {
      setErrorMessage(policyBuilderMessages.POLICY_ERROR_MESSAGE);
      setSaveEnabled(false);
    } else if (/\s/.test(event.target.value?.trim())) {
      setErrorMessage(policyBuilderMessages.POLICY_NAME_ERROR_SPACES);
      setSaveEnabled(false);
    } else if (event.target.value.includes('-')) {
      setErrorMessage(policyBuilderMessages.POLICY_NAME_ERROR_HYPHEN);
      setSaveEnabled(false);
    } else if (/[^A-Za-z0-9_]/g.test(event.target.value?.trim())) {
      setErrorMessage(
        policyBuilderMessages.POLICY_NAME_ERROR_ALPHANUMERIC_ONLY,
      );
      setSaveEnabled(false);
    } else if (searchData?.includes(event.target.value?.trim())) {
      setErrorMessage(policyBuilderMessages.DUPLICATE_POLICY_NAME);
      setSaveEnabled(false);
    } else if (event.target.value.length > 50) {
      setErrorMessage(policyBuilderMessages.POLICY_NAME_OUT_OF_LIMITS);
      setSaveEnabled(false);
    } else {
      setErrorMessage('');
      setSaveEnabled(true);
    }
    setPolicyBuilderName(event.target.value);
    handlePolicyNameUpdate(event.target.value);
  };
  // const handlePolicyBuilderTarget = () => {
  //   if (policyBuilderTarget == 'Test') {
  //     setPolicyBuilderTarget('Define');
  //   } else {
  //     setPolicyBuilderTarget('Test');
  //   }
  // };
  useEffect(() => {
    setPolicyBuilderName(policyName);
    setSaveEnabled(policyName && errorMessage === '' ? true : false);
  }, [policyName]);

  useEffect(() => {
    setIsEditable(editable);
  }, [editable]);

  const handleClickAway = () => {
    if (policyBuilderName && policyBuilderName !== '' && errorMessage === '') {
      setIsEditable(false);
    } else {
      setSaveEnabled(false);
      (!policyBuilderName || policyBuilderName === '') &&
        setErrorMessage(policyBuilderMessages.POLICY_ERROR_MESSAGE);
    }
  };

  return (
    <React.Fragment>
      <StyledPolicyHeader position="static">
        <Toolbar>
          <Grid container direction="row" width="18%">
            <Grid item sx={{ cursor: 'pointer' }} onClick={onCloseClick}>
              <IconFromSvg
                path={headerData?.entities.organizationLogo || DEFAULT_APP_ICON}
                height={36}
                alt="organization-logo"
              />
            </Grid>
            <Grid item sx={{ cursor: 'pointer' }} onClick={onCloseClick}>
              <Typography
                variant="subtitle1"
                sx={{
                  paddingLeft: theme.spacing(2.25),
                  paddingTop: theme.spacing(1.25),
                }}
              >
                {headerData?.entities.organizationName ??
                  messages.SONET_HEADING}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            flexWrap="nowrap"
            justifyContent="center"
            sx={{ marginLeft: 10, marginRight: 10 }}
          >
            {isEditable ? (
              <ClickAwayListener onClickAway={handleClickAway}>
                <Grid item>
                  <PolicyHelpTooltip
                    heading={policyBuilderMessages.HELP_TOOLTIP.POLICY_NAME}
                    subHeading={
                      policyBuilderMessages.HELP_TOOLTIP.POLICY_NAME_HELP
                    }
                    children={
                      <Grid item>
                        <StyledTextField
                          testId="policyname"
                          autoFocus
                          onFocus={(event) => {
                            event.target.select();
                          }}
                          inputProps={{ style: { height: 6 } }}
                          textFieldSize="small"
                          autoComplete="off"
                          error={errorMessage !== ''}
                          value={policyBuilderName}
                          handleChange={handlePolicyNameChange}
                          placeholder="policy name"
                          helperText={errorMessage}
                        />
                      </Grid>
                    }
                  />
                </Grid>
              </ClickAwayListener>
            ) : (
              <Grid
                item
                data-testid="editable"
                onClick={handleEditable}
                sx={{ cursor: 'pointer' }}
              >
                <Typography variant="subtitle2">{policyBuilderName}</Typography>
              </Grid>
            )}
            <Grid item sx={{ paddingLeft: 2, paddingTop: 1 }}>
              <IconFromSvg path={InformationSvg} alt="information" />
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="right"
            alignItems="center"
            flexWrap="nowrap"
            width="18%"
            spacing={2}
          >
            {/* <Grid item>
              <Button
                data-testid="define"
                variant="text"
                sx={{ fontWeight: 600, fontSize: 16 }}
                onClick={handleTestOrEdit}
                onClickCapture={handlePolicyBuilderTarget}
              >
                {policyBuilderTarget === 'Define'
                  ? policyBuilderMessages.TEST_POLICY
                  : policyBuilderMessages.EDIT_Policy}
              </Button>
            </Grid>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid> */}

            <PolicyIconsHelpTooltip
              content={policyBuilderMessages.HELP_TOOLTIP.SAVE_POLICY}
              children={
                <Grid item sx={{ paddingLeft: 1 }}>
                  <Button
                    variant="contained"
                    disabled={isSaveDisabled || !isSaveEnabled}
                    sx={{
                      borderRadius: theme.spacing(1),
                      width: theme.spacing(20),
                    }}
                    onClick={handleSave}
                  >
                    {savedData
                      ? policyBuilderMessages.BUTTON_LABELS.SAVED
                      : policyBuilderMessages.BUTTON_LABELS.SAVE}
                    {isSaving
                      ? (
                        <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 animate-spin">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                        </svg>
                      )
                      : null}
                  </Button>
                </Grid>
              }
            />

            <PolicyIconsHelpTooltip
              content={policyBuilderMessages.HELP_TOOLTIP.EXIT_POLICY}
              children={
                <Grid item>
                  <IconButton onClick={onCloseClick}>
                    <IconFromSvg path={CloseIcon} alt="close-icon" />
                  </IconButton>
                </Grid>
              }
            />
          </Grid>
        </Toolbar>
      </StyledPolicyHeader>
      <Box
        display="flex"
        flexDirection="row"
        sx={{
          width: 'inherit',
        }}
        marginTop={-1.5}
      >
        <Box
          flexGrow={1}
          sx={{ borderTop: `4px solid ${EXTRA_COLORS.cyan}` }}
        />
        <Box
          sx={{
            backgroundColor: EXTRA_COLORS.cyan,
            borderBottomLeftRadius: theme.spacing(2),
            borderBottomRightRadius: theme.spacing(2),
          }}
        >
          <Typography
            variant="body2"
            sx={{
              paddingLeft: 16,
              paddingRight: 16,
              color: EXTRA_COLORS.mediumCyan,
            }}
          >
            {policyBuilderTarget}
          </Typography>
        </Box>
        <Box
          flexGrow={1}
          sx={{ borderTop: `4px solid ${EXTRA_COLORS.cyan}` }}
        />
      </Box>
    </React.Fragment>
  );
};

export default PolicyBuilderHeader;
