import React, { useEffect, useState } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import policyBuilderMessages from '../../../core-utils/messages/policyBuilder';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import IconFromSvg from '../../atoms/IconFromSvg';
import SearchField from '../../molecules/SearchInput';
import ChevronRightIcon from '../../../../public/assets/chevron-right.svg';
import Tabs from '../Tabs';
import PolicyBlock, {
  IPolicyBlockProps,
} from '../../atoms/DraggablePolicyBlock';
import { useShowOrHideElement } from '../../../core-utils/Hooks/index.hook';
import RightPanelExpandCollapseState from '../../molecules/RightPanelExpandCollapseState';
import Button from '../../atoms/Button';
import AddIcon from '@mui/icons-material/Add';
import AddListModal from '../AddListModal';
import { IDropDownItem } from '../../../core-utils/constants';
import LoadingAnimation from '../../atoms/LoadingAnimation';
import { getConditionsDropdownFromConditionBlocks } from '../../../core-utils/Helper/helper';

interface IConditionsAndActions {
  conditionsAndActions: IPolicyBlockProps[];
  hideAddListItem?: boolean;
}

const ConditionsAndActions = ({ ...rest }: IConditionsAndActions) => {
  const [searchText, setSearchText] = React.useState(
    policyBuilderMessages.SEARCH_CONDITIONS,
  );
  const getConditions = () => {
    if (rest.conditionsAndActions.length) {
      return rest.conditionsAndActions.filter(
        (item) => item.type === 'condition',
      );
    } else {
      return [];
    }
  };
  const getActions = () => {
    if (rest.conditionsAndActions.length) {
      return rest.conditionsAndActions.filter((item) => item.type === 'action');
    } else {
      return [];
    }
  };
  const getLists = () => {
    if (rest.conditionsAndActions.length) {
      return rest.conditionsAndActions.filter((item) => item.type === 'list');
    } else {
      return [];
    }
  };
  const [conditions, setConditions] = React.useState(getConditions());
  const [actions, setActions] = React.useState(getActions());
  const [lists, setLists] = React.useState(getLists());
  const [searchTerm, setSearchTerm] = React.useState('');
  const [isAddListOpen, setIsAddListOpen] = React.useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  React.useEffect(() => {
    if (searchText === policyBuilderMessages.SEARCH_CONDITIONS) {
      const tempConditions = getConditions().filter((item) => {
        return item.children.toLowerCase().includes(searchTerm);
      });
      setConditions(tempConditions);
    } else if (searchText === policyBuilderMessages.SEARCH_ACTIONS) {
      const tempActions = getActions().filter((item) =>
        item.children.toLowerCase().includes(searchTerm),
      );
      setActions(tempActions);
    } else if (searchText === policyBuilderMessages.SEARCH_LISTS) {
      const tempLists = getLists().filter((item) =>
        item.children.toLowerCase().includes(searchTerm),
      );
      setLists(tempLists);
    }
    if (searchTerm == '') {
      setConditions(getConditions());
      setActions(getActions());
    }
  }, [searchTerm, searchText]);

  useEffect(() => {
    showElement();
  }, []);

  useEffect(() => {
    setLoading(true);
    setConditions(getConditions());
    setActions(getActions());
    setLists(getLists());
    setLoading(false);
  }, [rest.conditionsAndActions]);

  const addLinkClick = () => {
    // TODO: open ADD LIST modal
    setIsAddListOpen(true);
  };

  const handleCloseListModal = () => {
    setIsAddListOpen(false);
  };

  const tabs = [
    {
      tabId: 1,
      label: 'Conditions',
      tabpanel: loading ? (
        <LoadingAnimation />
      ) : (
        <Grid>
          {conditions.map((item) => {
            return (
              <Grid>
                <PolicyBlock {...item} />
              </Grid>
            );
          })}
        </Grid>
      ),
      onClick: () => {
        setSearchText(policyBuilderMessages.SEARCH_CONDITIONS);
        setActions(getActions());
      },
    },
    {
      tabId: 2,
      label: 'Actions',
      tabpanel: loading ? (
        <LoadingAnimation />
      ) : (
        <Grid>
          {actions.map((item) => {
            return (
              <Grid>
                <PolicyBlock {...item} />
              </Grid>
            );
          })}
        </Grid>
      ),
      onClick: () => {
        setSearchText(policyBuilderMessages.SEARCH_ACTIONS);
        setConditions(getConditions());
      },
    },
    {
      tabId: 3,
      label: 'Lists',
      tabpanel: loading ? (
        <LoadingAnimation />
      ) : (
        <Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ px: '5%', width: '90%', height: '90%' }}
          >
            <Typography variant="body2">{'Lists'}</Typography>
            <Button onClick={addLinkClick} startIcon={<AddIcon />}>
              {'Add List'}
            </Button>
            <AddListModal
              showModal={isAddListOpen}
              dropDownOptions={getConditionsDropdownFromConditionBlocks(
                conditions,
              )}
              handleClose={handleCloseListModal}
            />
          </Grid>
          <Grid sx={{ px: '5%', mb: 4 }}>
            <Divider />
          </Grid>
          {lists.map((item) => {
            return (
              <Grid>
                <PolicyBlock {...item} />
              </Grid>
            );
          })}
        </Grid>
      ),
      onClick: () => {
        setSearchText(policyBuilderMessages.SEARCH_LISTS);
        setLists(getLists());
      },
    },
  ];

  const { open, showElement, hideElement } = useShowOrHideElement();

  const getRequiredTabs = () => {
    if (rest.hideAddListItem) {
      return tabs.filter((data) => data.tabId !== 3); //* disabling add List tab item
    }
    return tabs;
  };

  return open ? (
    <Grid
      sx={{
        height: '100%',
        width: '100%',
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${EXTRA_COLORS.lightGrayishBlue}`,
        padding: '12px 0px 350px 1px',
        boxSizing: 'border-box',
      }}
    >
      <Grid
        container
        item
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{ ml: '5%', mr: '5%', width: '90%' }}
      >
        <Typography>{policyBuilderMessages.CONDITIONS_AND_ACTIONS}</Typography>
        <Grid item onClick={hideElement} sx={{ cursor: 'pointer' }}>
          <IconFromSvg path={ChevronRightIcon} alt={'chevron-right'} />
        </Grid>
      </Grid>
      <SearchField
        placeholder={searchText}
        sx={{ width: '90%', ml: '5%', mr: '5%' }}
        handleChange={(e) => {
          setSearchTerm(e.target.value);
        }}
      />
      <Tabs tabs={getRequiredTabs()} />
    </Grid>
  ) : (
    <Grid container justifyContent="right" paddingTop="12px">
      <Grid item sx={{ cursor: 'pointer' }}>
        <RightPanelExpandCollapseState handleOpen={showElement} />
      </Grid>
    </Grid>
  );
};

export default ConditionsAndActions;
