import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { sessionActions } from 'src/Redux/Reducers/session';
import { userDetailsActions } from 'src/Redux/Reducers/userDetails';
import Config from '../../../config/config';
import LoginService, {
  ILoginDetails,
} from '../../api-service/login-service/LoginService';
import SettingsService from '../../api-service/settings-service/SettingsService';
import UserService from '../../api-service/user-service/UserService';
import {
  headerAction,
  updateHeaderData,
} from '../../Redux/Reducers/header';
import { sideNavActions } from '../../Redux/Reducers/sideNav';
import { useAppDispatch, useAppSelector } from '../../Redux/Store/store';

import {
  EMAIL,
  FIRST_TIME_LOGGEDIN,
  FULL_NAME,
  IDP_CONNECTION_ID,
  ORGANIZATION,
  ROLE,
  SOURCE,
  TOKEN,
  USER_ID,
} from '../constants';
import {
  getUserFullNameOrUsername,
  refreshAuthToken
} from '../Helper/helper';
import messages from '../messages';
import { ROUTES } from '../routes';

export interface IContext {
  userEmail: string;
  userId: string;
  token: string;
  login: Function;
  logout: Function;
}

const authContext = createContext({} as IContext);

export const ProvideAuth = ({ children }: any) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

export const useProvideAuth = () => {
  const [userEmail, setUserEmail] = useState('');
  const [isNetworkOnline, setIsNetworkOnline] = useState<boolean>(true);
  const intervalRef: any = useRef();
  const dispatch = useAppDispatch();

  const token = useAppSelector(
    (state) => state?.token?.session?.authToken
  );
  const userId = useAppSelector(
    (state) => state?.token?.session?.username
  );

  const isNetworkDisconnectedError = (e: any) => e.message === 'Error: Network Error';


  useEffect(() => {
    window.addEventListener("offline", () => {
      setIsNetworkOnline(false);
    });
    window.addEventListener("online", () => {
      setIsNetworkOnline(true);
    });

    return () => {
      window.removeEventListener("offline", () => {
        setIsNetworkOnline(false);
      });
      window.removeEventListener("online", () => {
        setIsNetworkOnline(true);
      });
    };
  }, []);

  React.useEffect(() => {
    if (token && isNetworkOnline) {
      refreshAuthToken(token)
      .catch((e) => {
        if(!isNetworkDisconnectedError(e)) {
            logout();
            window.location.reload();
        }
      });

      // Calling the refresh auth token API periodically to keep the token alive
      intervalRef.current = intervalRef.current || setInterval(() => {
        if (token && isNetworkOnline) {
          refreshAuthToken(token)
            .catch((e) => {
              if (!isNetworkDisconnectedError(e)) {
                logout();
                const currentPath = window.location.pathname;
                let redirectUrl = ROUTES.LOGIN;
                if (currentPath !== ROUTES.LOGIN) {
                  redirectUrl += `?redirect=${currentPath}`
                }
                window.location.replace(redirectUrl);
                if (intervalRef.current) {
                  clearInterval(intervalRef.current);
                  intervalRef.current = false;
                } 
              }
            });
        } else {
          if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = false;
          }
        }
      }, Number(Config.TOKEN_EXPIRATION_TIME));
    }

    return () => { intervalRef.current && clearInterval(intervalRef.current); };
  }, [token, isNetworkOnline]);

  React.useEffect(() => {
    const handleLogOut = (event: any) => {
      if (event.key == 'logout-event') {
        dispatch(headerAction.resetHeaderData({}));
        dispatch(sessionActions.clearSession());
        setUserEmail('');
        const currentPath = window.location.pathname;
        let redirectUrl = ROUTES.LOGIN;
        if (currentPath !== ROUTES.LOGIN) {
          redirectUrl += `?redirect=${currentPath}`
        }
        window.location.replace(redirectUrl);
      }
    };
    window.addEventListener('storage', handleLogOut);

    return () => window.removeEventListener('storage', handleLogOut);
  }, []);

  // Getting Header Data and setting it to redux
  const initializeHeaderData = async () => {
    const accountSettingsResponse = await SettingsService.getAccountSettings();
    dispatch(
      updateHeaderData({
        userName: getUserFullNameOrUsername(),
        organizationName: accountSettingsResponse?.organizationName,
        organizationLogo: accountSettingsResponse?.organizationLogo,
        organizationPhone: accountSettingsResponse?.organizationPhone,
        organizationPhoneCode: accountSettingsResponse?.organizationPhoneCode,
        organizationAddress: accountSettingsResponse?.organizationAddress,
        suppportEmailAddress: accountSettingsResponse?.suppportEmailAddress,
        language: accountSettingsResponse?.language,
        timeZone: accountSettingsResponse?.timeZone,
      }),
    );
  };

  const isAlreadyLoggedIn = () => {
    const token = localStorage.getItem(TOKEN);
    if (token) {
      return true;
    }
    return false;
  };

  const login = async (
    userLoginCredentials: ILoginDetails,
    successCallback?: () => void,
    errorCallback?: (message?: string, errorType?: string) => void,
  ) => {
    try {
      const response = await LoginService.login(userLoginCredentials);
      const { authToken, username: userId, idpConnectionId } = response;
      dispatch(sessionActions.setSession(response));
      if (authToken) {
        if (isAlreadyLoggedIn()) {
          const token = localStorage.getItem(TOKEN);
          await LoginService.deleteAuthToken(token);
          logout();
          localStorage.setItem('logout-event', 'logout');
          localStorage.removeItem('logout-event');
        }
        localStorage.setItem(TOKEN, authToken);
        localStorage.setItem(USER_ID, userId ?? '');
        const setUserDetails = async () => {
          const userDetails = await UserService.getUserById(userId);
          dispatch(userDetailsActions.setUserDetails(userDetails));
          const { attributes} = userDetails;
          const fullName = attributes?.['guac-full-name'];
          const organization = attributes?.['guac-organization'];
          const email = attributes?.['guac-email-address'];
          const source = attributes?.['sonet-source'];
          const role = attributes?.['jobRole'];
          const { systemPermissions } = await UserService.getAppsOfUser(userId);
          // const role = systemPermissions?.includes('ADMINISTER') ? 'admin' : '';
          dispatch(sessionActions.setUserRole(role));
          const firstLoggedIn = sessionStorage.getItem(FIRST_TIME_LOGGEDIN);
          localStorage.setItem(ROLE, role);
          localStorage.setItem(FULL_NAME, fullName ?? '');
          localStorage.setItem(ORGANIZATION, organization);
          localStorage.setItem(EMAIL, email);
          localStorage.setItem(SOURCE, source);
          localStorage.setItem(IDP_CONNECTION_ID, idpConnectionId);
          await initializeHeaderData();
          !firstLoggedIn &&
            sessionStorage.setItem(FIRST_TIME_LOGGEDIN, 'LOGIN');
          dispatch(sideNavActions.loadSideNavAppRoutes());
        };
        await setUserDetails();
        successCallback && successCallback();
      } else {
        errorCallback &&
          errorCallback(
            response.response?.data?.message ??
            messages.INVALID_LOGIN_CREDENTIALS,
            response.response?.data?.type
          );
      }
    } catch (error: any) {
      console.log(error);
      errorCallback &&
        errorCallback(error?.message ?? messages.SOMETHING_WENT_WRONG, error?.type);
    }
  };

  const logout = (cb?: () => void) => {
    localStorage.setItem('logout-event', 'logout');
    localStorage.removeItem('logout-event');
    localStorage.removeItem(IDP_CONNECTION_ID);
    localStorage.removeItem('persist:root');
    localStorage.clear();
    dispatch(headerAction.resetHeaderData({}));
    dispatch(sessionActions.clearSession());
    setUserEmail('');
    cb && cb();
  };

  return {
    userEmail,
    token,
    userId,
    login,
    logout,
  };
};
