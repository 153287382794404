import {
  Alert,
  AlertTitle,
  Box,
  Snackbar,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import PolicyService, {
  IAddPolicyRequest,
  IConditionBlock,
} from '../../../api-service/policy-service/PolicyService';
import {
  DRAGGABLE_POLICY__ITEMS_ID,
  HIDE_ADD_LIST_ITEM_IN_POLICY,
  IDropDownItem,
  MatchParams,
} from '../../../core-utils/constants';
import { IPolicyBlockProps } from '../../atoms/DraggablePolicyBlock';
import DragAndDropEmptyStateIcon from '../../../../public/assets/dragAndDropEmptyState.svg';
import EditActiveSvg from '../../../../public/assets/Combined-Shape.svg';
import LoadingAnimation from '../../atoms/LoadingAnimation';
import AddPolicyBlockDivider from '../../molecules/AddPolicyBlockDivider/index';
import EmptyStateWithIconDividerTextAndButton from '../../molecules/EmptyStateWithIconDividerTextAndButton';
import ConditionsAndActions from '../../organisms/ConditionsAndActions';
import PolicyBlocksPanel from '../../organisms/PolicyBlocksPanel';
import PolicyBuilderHeader from '../../organisms/PolicyBuilderHeader';
import emptyStateMessages from '../../../core-utils/messages/emptyState';
import usePolicyBuilder from '../PolicyBuilderDashboard/index.hook';
import PolicyBlockTooltipGuide from '../../molecules/TooltipForPolicyBlock';
import policyMessages from '../../../core-utils/messages/policyMessages';
import IconFromSvg from '../../atoms/IconFromSvg';
import PolicyBuilderConfirmation from '../../molecules/PolicyBuilderConfirmationPopup';
import policyBuilderMessages from '../../../core-utils/messages/policyBuilder';
import { ROUTES } from '../../../core-utils/routes';
import { EXTRA_COLORS } from '../../../core-utils/theme';
import PolicyBuilderDashboard from '../PolicyBuilderDashboard';
import { formattedPolicyBlockListResponse } from './index.hook';
import AlertInSnackBar from '../../molecules/AlertInSnackBar';
import { getPolicies } from '../../organisms/PoliciesDashboard/index.hook';

const emptyPolicyData = {
  name: '',
  content: [],
};

export const SnackBarContext = React.createContext((prevState: any) => {});

const PolicyBuilderPage = (props: RouteComponentProps<MatchParams>) => {
  const [policyBlocks, setPolicyBlocks] = useState<IPolicyBlockProps[]>([]);
  const [policyBlockResponse, setPolicyBlockResponse] = useState<Array<any>>(
    [],
  );
  const [showSelectPolicyBlock, setShowSelectPolicyBlock] = useState(false);
  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);
  const [snackBarMessages, setSnackBarMessages] = useState<{
    title: string;
    subtitle: string;
    saved?: boolean;
  }>({
    title: policyBuilderMessages.SNACKBAR_MESSAGES.TITLE,
    subtitle: policyBuilderMessages.SNACKBAR_MESSAGES.SUBHEADING,
    saved: false,
  });

  const [loading, setLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [policyPayload, setPolicyPayload] = useState<any>(emptyPolicyData);
  const [policyConditions, setPolicyConditions] = useState<IDropDownItem[]>([]);

  const [policyId, setPolicyId] = useState(props.match.params?.id);
  const [isEditable, setEditable] = useState<boolean>(true);

  const [isSaveDisabled, setSaveDisabled] = useState(true);
  const [savedPolicyData, setSavedPolicyData] = useState<any>();
  const [errorState, setErrorState] = useState<boolean>(false);
  const [searchData, setSearchData] = useState<string[]>([]);
  const [isSaved, setSaved] = useState<boolean>(false);
  const [style, setStyle] = useState({});
  const theme = useTheme();
  const history = useHistory();
  const resetSelectPolicyBlockState = (value: boolean) => {
    setShowSelectPolicyBlock(value);
  };

  const {
    policyCardData,
    userGroupDropdown,
    applicationDropdown,
    urlDropdown,
    handleAddCondition,
    handleCopyCard,
    handleDeleteCard,
    handleDeleteRow,
    handlePolicyChange,
    handlePolicyValueDropdownChange,
    handlePolicyValueTextChange,
    handleSaveCard,
    handleToggleCollapse,
    handleEquationChange,
    handleCollapseAll,
    handleDropPolicyBlock,
    handleDropConditions,
    isSnackbarOpen,
    onOpenSnackbar,
    onCloseSnackbar,
    getAddPolicyRequest,
    focusedCardId,
    policyConditionsActionsLists,
    handleClickCard,
    snackBarMessage,
    handlePolicyActionsChange,
    handleChangeTimeValue,
    initializePolicyCardData,
    isSaveEnabled,
    handleJoinCondition,
  } = usePolicyBuilder({
    handleSelectPolicyBlock: resetSelectPolicyBlockState,
    policyBlockItemResponse: policyBlockResponse,
  });

  useEffect(() => {
    snackBarMessage && setSnackBarMessages(snackBarMessage);
  }, [snackBarMessage]);

  const loadPolicies = async () => {
    getPolicies().then((response) => {
      setSearchData(response.resultSearchData);
    });
  };

  const getAllPolicyList = async () => {
    const policyBlocks: any = await PolicyService.getAllPolicyBlocks();
    const allPolicyBlocks: any = await formattedPolicyBlockListResponse(
      policyBlocks,
    );

    const policyBlocksList: IPolicyBlockProps[] = allPolicyBlocks.map(
      (policyBlock: any, index: number) => {
        return {
          type: 'default',
          children: policyBlock?.name,
          handleDragStart: (event: any) => {
            event.dataTransfer.setData('text', event.target.id);
          },
          elementId: `${DRAGGABLE_POLICY__ITEMS_ID.POLICY_BLOCK_ID}_${policyBlock.id}`,
        } as IPolicyBlockProps;
      },
    );
    const policyBlockResponse: any = {};
    allPolicyBlocks.map((policyBlock: any) => {
      policyBlockResponse[policyBlock.id] = {
        name: policyBlock?.name,
        type: policyBlock?.type,
        expanded: true,
        defaultAction: null,
        defaultActionOptions: policyBlock?.defaultActions,
        policyConditions: policyBlock?.conditionList?.map(
          (condition: any, index: number) => {
            return {
              parameterValue: {
                id: index,
                value: condition?.name,
              },
              equationValue: {
                id: index,
                value: condition.operations[0],
              },
              policyValue: {
                id: index,
                value: condition.attributes,
              },
              allowanceValue: {
                id: index,
                value: condition.actions,
              },
            };
          },
        ),
      };
    });

    return [policyBlocksList, policyBlockResponse];
  };

  const handlePolicyBuilderValidation = (requestBody: IAddPolicyRequest) => {
    if (requestBody.policyName) {
      const content = requestBody.policyContent;
      for (var i = 0; i < content.length; i++) {
        if (!content[i].defaultAction) {
          return false;
        } else {
          const conditionList = content[i].conditionList;
          for (var j = 0; j < conditionList.length; j++) {
            const conditionBlock = conditionList[j].conditionBlock;
            for (var k = 0; k < conditionBlock.length; k++) {
              if (
                !conditionBlock[k].name ||
                !conditionBlock[k].operator ||
                !conditionBlock[k].value ||
                conditionBlock[k]?.action?.length === 0
              ) {
                return false;
              }
            }
          }
        }
      }
    } else {
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    const createPolicyRequest: IAddPolicyRequest = getAddPolicyRequest(
      policyPayload.name,
    );
    console.log(createPolicyRequest, 'policy request');
    if (handlePolicyBuilderValidation(createPolicyRequest)) {
      setIsSaving(true);
      setErrorState(false);
      if (policyId) {
        const policyData = await PolicyService.updatePolicy(
          createPolicyRequest,
          policyId,
        );
        const parsedData = JSON.parse(policyData);
        const dataWithStringifiedPolicyContent = {
          ...parsedData,
          policyContent: JSON.stringify(parsedData.policyContent),
        };
        setSavedPolicyData(dataWithStringifiedPolicyContent);

        setSaved(true);
        setIsSaving(false);
        setSnackBarMessages({
          title: policyBuilderMessages.SNACKBAR_MESSAGES.SAVED_SUCCESSFULLY,
          subtitle: '',
          saved: true,
        });
        onOpenSnackbar();
        setSaveDisabled(true);
      } else {
        const policyData = await PolicyService.addPolicy(createPolicyRequest);
        setSavedPolicyData(policyData);
        setSaved(true);
        setIsSaving(false);
        history.replace(`editpolicy/${policyData.identifier}`);
        setLoading(true);
        loadPolicyData(policyData.identifier)
          .then()
          .finally(() => setLoading(false));
        setPolicyId(policyData.identifier);
      }
    } else {
      setSnackBarMessages({
        title: policyBuilderMessages.SNACKBAR_MESSAGES.FIELD_EMPTY_TITLE,
        subtitle: policyBuilderMessages.SNACKBAR_MESSAGES.FIELD_EMPTY_SUBTITLE,
      });
      setErrorState(true);
      onOpenSnackbar();
    }
  };

  const handlePolicyNameChange = (policyName: string) => {
    if (policyName) {
      setPolicyPayload({ ...policyPayload, name: policyName });
    }
    getStyles(policyName);
  };

  const handleAddPolicyBlock = () => {
    resetSelectPolicyBlockState(!showSelectPolicyBlock);

    //TODO : in the next PR
  };
  // TODO : To integrate with close policy popup when changes get merged.
  const handleCloseModal = () => {
    if (!isSaveDisabled) {
      setIsCloseModalOpen(true);
    } else {
      onLeaveClick();
    }
  };

  const handleDropPolicyBlockEvent = (event: any) => {
    const droppedItemId = event.dataTransfer.getData('text');
    if (droppedItemId.startsWith(DRAGGABLE_POLICY__ITEMS_ID.POLICY_BLOCK_ID)) {
      handleDropPolicyBlock(droppedItemId.split('_')[1]);
    }
  };

  const loadPolicyData = async (policyId: string) => {
    const policyData = await PolicyService.getPolicyById(policyId ?? '');
    setSavedPolicyData(policyData);
    setPolicyPayload({ ...policyPayload, name: policyData.policyName });
    await initializePolicyCardData(policyData);
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      loadPolicies(),
      getAllPolicyList().then((response) => {
        setPolicyBlocks(response[0]);
        setPolicyBlockResponse(response[1]);
      }),
    ])
      .then()
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    loadPolicyData(policyId ?? '')
      .then(() => {
        setEditable(false);
      })
      .finally(() => {
        setLoading(false);
        setSnackBarMessages({
          title: policyBuilderMessages.SNACKBAR_MESSAGES.TITLE,
          subtitle: policyBuilderMessages.SNACKBAR_MESSAGES.SUBHEADING,
          saved: false,
        });
      });
  }, [policyBlockResponse]);

  //compare saved policy with current policy state
  const comparePolicyData = (policyRequest: any, savedPolicy: any) => {
    if (policyRequest && savedPolicy) {
      //compare names
      if (policyRequest.policyName !== savedPolicy.policyName) {
        return false;
      }
      //compare policy block
      if (
        JSON.stringify(policyRequest.policyContent) !==
        savedPolicy.policyContent
      ) {
        return false;
      }
    }
    return true;
  };

  //set save disabled when the state change
  useEffect(() => {
    //get the current policy data and compare it with saved policy data
    const createPolicyRequest: IAddPolicyRequest = getAddPolicyRequest(
      policyPayload.name,
    );
    if (policyId && comparePolicyData(createPolicyRequest, savedPolicyData)) {
      setSaveDisabled(true);
      setSaved(true);
      return;
    }

    if (isSaveEnabled) {
      setSaveDisabled(!handlePolicyBuilderValidation(createPolicyRequest));
      setSaved && setSaved(false);
    } else {
      setSaveDisabled(true);
      setSaved(false);
    }
    getStyles();
  }, [policyCardData, policyPayload]);

  const emptyStateAddPolicyBlock: JSX.Element = (
    <PolicyBlockTooltipGuide
      heading={policyMessages.POLICY_BLOCK.TOOLTIP_HEADING}
      icon={<IconFromSvg path={EditActiveSvg} alt="tooltip policy" />}
      children={
        <EmptyStateWithIconDividerTextAndButton
          icon={DragAndDropEmptyStateIcon}
          text={emptyStateMessages.DRAG_AND_DROP}
          buttonText={emptyStateMessages.ADD_POLICY_BLOCK}
          width={theme.spacing(62.5)}
          onButtonClick={handleAddPolicyBlock}
          handleDrop={handleDropPolicyBlockEvent}
        />
      }
      body={policyMessages.POLICY_BLOCK.TOOLTIP_BODY}
    />
  );
  const nonEmptyAddPolicyBlock: JSX.Element = (
    <AddPolicyBlockDivider
      handleButtonClick={handleAddPolicyBlock}
      handleDrop={handleDropPolicyBlockEvent}
    />
  );

  const closePolicyBlock = () => {
    resetSelectPolicyBlockState(false);
  };

  const onCancelClick = () => {
    setIsCloseModalOpen(false);
  };

  const onLeaveClick = () => {
    history.replace(ROUTES.POLICIES);
  };

  const handleSelectPolicyBlock = (itemId: string) => {
    if (itemId.startsWith(DRAGGABLE_POLICY__ITEMS_ID.POLICY_BLOCK_ID)) {
      handleDropPolicyBlock(parseInt(itemId.charAt(itemId.length - 1)));
      closePolicyBlock();
    }
  };

  const getSnackBar = () => {
    if (snackBarMessages.saved) {
      return (
        <AlertInSnackBar
          isSnackBarOpen={isSnackbarOpen}
          severity="success"
          handleCloseSnackBar={onCloseSnackbar}
          title={snackBarMessages.title}
        />
      );
    } else if (errorState) {
      return (
        <AlertInSnackBar
          isSnackBarOpen={isSnackbarOpen}
          severity="error"
          handleCloseSnackBar={onCloseSnackbar}
          title={snackBarMessages.title}
          subtitle={snackBarMessages.subtitle}
        />
      );
    } else {
      return (
        <AlertInSnackBar
          isSnackBarOpen={isSnackbarOpen}
          severity="warning"
          handleCloseSnackBar={onCloseSnackbar}
          title={snackBarMessages.title}
          subtitle={snackBarMessages.subtitle}
        />
      );
    }
  };

  const getStyles = (policyName?: string) => {
    const name = policyName ?? policyPayload?.name;
    if (name && name !== '') {
      setStyle({});
    } else {
      setStyle({ opacity: 0.2, cursor: 'not-allowed' });
    }
  };

  return (
    <SnackBarContext.Provider value={setSnackBarMessages}>
      <Box height="100%" width="100%">
        <PolicyBuilderHeader
          target="Define"
          policyName={policyPayload?.name}
          handlePolicyNameUpdate={handlePolicyNameChange}
          onCloseClick={handleCloseModal}
          handleSave={handleSave}
          isSaving={isSaving}
          isSaveDisabled={isSaveDisabled}
          editable={isEditable}
          searchData={searchData}
          savedData={isSaved}
        />
        {loading ? (
          <LoadingAnimation />
        ) : (
          <Box display="flex" sx={{ ...style }}>
            <PolicyBuilderConfirmation
              open={isCloseModalOpen}
              cancelButtonLabel={policyBuilderMessages.BUTTON_LABELS.CANCEL}
              leaveButtonLabel={policyBuilderMessages.BUTTON_LABELS.LEAVE}
              heading={policyBuilderMessages.CLOSE_BUILDER_LABELS.HEADING}
              subHeading={policyBuilderMessages.CLOSE_BUILDER_LABELS.SUBHEADING}
              onLeave={onLeaveClick}
              onCancel={onCancelClick}
            />
            <Box sx={{ width: '17%' }}>
              <PolicyBlocksPanel policyBlocks={policyBlocks} />
            </Box>
            <Box sx={{ width: '64%' }}>
              <PolicyBuilderDashboard
                focusedCardId={focusedCardId}
                policyCardData={policyCardData}
                handleClickCard={handleClickCard}
                handlePolicyChange={handlePolicyChange}
                handleToggleCollapse={handleToggleCollapse}
                handleEquationChange={handleEquationChange}
                userGroupDropdown={userGroupDropdown}
                applicationDropdown={applicationDropdown}
                urlDropdown={urlDropdown}
                handleOpenSnackbar={onOpenSnackbar}
                handleDeleteRow={handleDeleteRow}
                handlePolicyValueTextChange={handlePolicyValueTextChange}
                handlePolicyValueDropdownChange={
                  handlePolicyValueDropdownChange
                }
                handlePolicyActionChange={handlePolicyActionsChange}
                handleAddCondition={handleAddCondition}
                handleDeleteCard={handleDeleteCard}
                handleCopyCard={handleCopyCard}
                handleSaveCard={handleSaveCard}
                addPolicyBlockEmptyMolecule={emptyStateAddPolicyBlock}
                addPolicyBlockMolecule={nonEmptyAddPolicyBlock}
                showSelectPolicyBlock={showSelectPolicyBlock}
                policyBlocks={policyBlocks}
                handleCollapseAll={handleCollapseAll}
                handleDropConditions={handleDropConditions}
                handleSelectPolicyBlock={handleSelectPolicyBlock}
                handleChangeTimeValue={handleChangeTimeValue}
                handleJoinCondition={handleJoinCondition}
              />
              {getSnackBar()}
            </Box>
            {policyCardData?.length > 0 && (
              <Box sx={{ width: '19%' }}>
                <ConditionsAndActions
                  conditionsAndActions={policyConditionsActionsLists}
                  hideAddListItem={HIDE_ADD_LIST_ITEM_IN_POLICY} //* this will hide the add list item
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </SnackBarContext.Provider>
  );
};

export default PolicyBuilderPage;
