import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { IDropdownOptionProps } from '.';
import PolicyService from '../../../api-service/policy-service/PolicyService';
import { useLogsFilter } from '../LogsDashboard/index.hook';

const useLocationAutocomplete = () => {
  const [inputValue, setInputValue] = useState('');

  const [inputSearch, setInputSearch] = useState('');

  const [ddoptions, setDdOptions] = useState<Array<IDropdownOptionProps>>([
    { id: 1, name: '' },
  ]);

  const debounce = (func: any, wait: number) => {
    let timeout: any;
    return (...args: any) => {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  };

  const debounceOnChange = React.useCallback(
    debounce((value: string) => {
      setInputSearch(value);
    }, 400),
    [],
  );

  function handleChange(value: string) {
    if (value.length > 2) {
      setInputValue(value);
      debounceOnChange(value);
    }
  }

  const loadOptions = async (inputValue: string) => {
    if (inputSearch !== '') {
      const cities = await PolicyService.getCitiesList(inputValue);
      console.log(cities);
      const uniq = cities.filter((elem: any, index: number, self: any[]) => {
        return index === self.indexOf(elem);
      });
      setDdOptions(
        uniq.map((data: any, index: number) => {
          return {
            id: index,
            name: data['name'],
            metadata: { ...data },
          };
        }),
      );
    }
  };

  useEffect(() => {
    loadOptions(inputValue);
  }, [inputSearch]);

  return { ddoptions, handleChange };
};

export default useLocationAutocomplete;
