import { current } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { RootStateOrAny } from 'react-redux';
import UserService from 'src/api-service/user-service/UserService';
import {
  ITooltipValidationParams,
  PASSWORD_PARAMETERS,
} from '../../../core-utils/constants';
import {
  checkPassword,
  getUserId,
  isEmptyValidation,
  isPasswordValid,
  validatePhone,
} from '../../../core-utils/Helper/helper';
import onBoardingMessages from '../../../core-utils/messages/onBoarding';
import { useAppSelector } from '../../../Redux/Store/store';

const {
  USER_NAME,
  USER_PHONE,
  USER_LOGO,
  USER_PHONE_CODE,
} = onBoardingMessages.INFORMATION_FORM;

const {
  OLD_PASSWORD_LABEL,
  NEW_PASSWORD_LABEL,
  CONFIRM_PASSWORD,
  NEW_PASSWORD_ERROR,
  INVALID_PASSWORD,
  CONFIRM_NEW_PASSWORD_ERROR,
} = onBoardingMessages.END_USER_PASSWORD_FORM;

const emptyPasswordParams = {
  [OLD_PASSWORD_LABEL]: '',
  [NEW_PASSWORD_LABEL]: '',
  [CONFIRM_PASSWORD]: '',
};

// Handle all the states , event handlers of the onboarding forms in this hook only
const useEndUserOnboardingPageData = (source: string) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [totpVerificationSuccess, setTotpVerificationSuccess] = useState(false);

  const getValidationFormForLocalUser = (step: number) => {
    if (step === 0) {
      handlePasswordFormValidation();
    } else if (step == 1) {
      setIsButtonDisabled(!totpVerificationSuccess);
    } else if (step === 2) {
      handleUserInformationValidation();
    } else {
      setIsButtonDisabled(false);
    }
  };

  const getValidationFormForOktaUser = (step: number) => {
    if (step === 0) {
      handleUserInformationValidation();
    } else {
      setIsButtonDisabled(false);
    }
  };

  const onBackClick = () => {
    setCurrentStep((prevState) => prevState - 1);
  };

  const onNextClick = () => {
    setCurrentStep((prevState) => prevState + 1);
  };

  useEffect(() => {
    if (source === 'okta') {
      getValidationFormForOktaUser(currentStep);
    } else {
      getValidationFormForLocalUser(currentStep);
    }
  }, [currentStep]);

  //Password Form Handlers
  const [passwordFormDetails, setPasswordFormDetails] = useState(
    emptyPasswordParams,
  );
  const [passwordTooltipParams, setPasswordTooltipParams] = useState<
    ITooltipValidationParams[]
  >(PASSWORD_PARAMETERS);
  const [passwordErrorMessages, setPasswordErrorMessages] = useState({
    [OLD_PASSWORD_LABEL]: '',
    [NEW_PASSWORD_LABEL]: '',
    [CONFIRM_PASSWORD]: '',
  });
  const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState('');

  const handlePasswordTextFieldChange = (name: string, value: string) => {
    setPasswordFormDetails((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === NEW_PASSWORD_LABEL) {
      setPasswordTooltipParams(checkPassword(value, passwordTooltipParams));
    }
  };

  const validateEndUserConfirmPassword = () => {
    if (
      passwordFormDetails[CONFIRM_PASSWORD] !==
      passwordFormDetails[NEW_PASSWORD_LABEL]
    ) {
      setPasswordErrorMessages({
        ...passwordErrorMessages,
        [CONFIRM_PASSWORD]: CONFIRM_NEW_PASSWORD_ERROR,
      });
      return false;
    } else {
      setPasswordErrorMessages({
        ...passwordErrorMessages,
        [CONFIRM_PASSWORD]: '',
      });
      return true;
    }
  };

  const validateEndUserPassword = () => {
    if (passwordFormDetails[NEW_PASSWORD_LABEL] === '') {
      return false;
    }
    if (!isPasswordValid(passwordFormDetails[NEW_PASSWORD_LABEL])) {
      setNewPasswordErrorMessage(INVALID_PASSWORD);
      return false;
    } else {
      setNewPasswordErrorMessage('');
      return true;
    }
  };

  const handlePasswordFormValidation = () => {
    const isValid = Object.keys(passwordFormDetails).every((key) => {
      if (
        (key === OLD_PASSWORD_LABEL &&
          isEmptyValidation(passwordFormDetails[key])) ||
        passwordFormDetails[NEW_PASSWORD_LABEL] === ''
      ) {
        return false;
      }
      if (key === NEW_PASSWORD_LABEL) {
        if (
          passwordFormDetails[OLD_PASSWORD_LABEL] === passwordFormDetails[key]
        ) {
          setNewPasswordErrorMessage(NEW_PASSWORD_ERROR);
          return false;
        } else {
          setNewPasswordErrorMessage('');
        }
        if (!validateEndUserPassword()) {
          return false;
        }
      }
      if (key === CONFIRM_PASSWORD && !validateEndUserConfirmPassword()) {
        return false;
      }
      return true;
    });
    setIsButtonDisabled(!isValid);
  };

  useEffect(() => {
    handlePasswordFormValidation();
  }, [passwordFormDetails]);

  //User Information Form Handlers
  const mainHeaderData = useAppSelector(
    (state: RootStateOrAny) => state.header,
  );

  const emptyInformationParams = {
    [USER_NAME]: mainHeaderData?.entities?.userName,
    [USER_PHONE]: '',
    [USER_LOGO]: '',
    [USER_PHONE_CODE]: '',
  };

  const [userInformationDetails, setUserInformationDetails] = useState(
    emptyInformationParams,
  );
  const handlePhoneCodeChange = (value: any) => {
    setUserInformationDetails((prevState: any) => ({
      ...prevState,
      [USER_PHONE_CODE]: value,
    }));
  };
  const handleLogoChange = (event: any) => {
    let reader = new FileReader();
    reader.onload = (e: any) => {
      setUserInformationDetails((prevState) => ({
        ...prevState,
        [USER_LOGO]: e.target.result,
      }));
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const handleInformationTextFieldChange = (name: string, value: string) => {
    setUserInformationDetails((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRemoveImage = () => {
    setUserInformationDetails((prevState) => ({
      ...prevState,
      [USER_LOGO]: '',
    }));
  };

  const handleUserInformationValidation = () => {
    const isValid = Object.keys(userInformationDetails).every((key) => {
      if (
        key === USER_NAME &&
        isEmptyValidation(userInformationDetails[key].trim())
      ) {
        return false;
      }
      // if (
      //   key === USER_PHONE_CODE &&
      //   isEmptyValidation(userInformationDetails[key])
      // ) {
      //   return false;
      // }
      // if (key === USER_PHONE && !validatePhone(userInformationDetails[key])) {
      //   return false;
      // }
      return true;
    });
    setIsButtonDisabled(!isValid);
  };

  // Authentication code form handler
  const [authenticationData, setAuthenticationData] = useState<{
    verificationCode: string;
    isVerifyButtonEnabled: boolean;
    errorMessage: string;
  }>({
    verificationCode: '',
    isVerifyButtonEnabled: false,
    errorMessage: '',
  });

  const handleChangeVerificationCode = (event: any) => {
    const verificationCode = event.target.value?.trim();
    if (/^[\d]{6}$/g.test(verificationCode)) {
      setAuthenticationData({
        verificationCode: verificationCode,
        isVerifyButtonEnabled: true,
        errorMessage: '',
      });
      //setIsButtonDisabled(false);
    } else {
      setAuthenticationData({
        verificationCode: verificationCode,
        isVerifyButtonEnabled: false,
        errorMessage: 'Only 6 digits allowed',
      });
      setIsButtonDisabled(true);
    }
  };

  const handleVerifyTotpEnrolment = async () => {
    const verificationSuccess = await UserService.verifyTotpEnrolment(
      getUserId() ?? '',
      authenticationData.verificationCode,
    );
    if (verificationSuccess) {
      setIsButtonDisabled(false);
      setTotpVerificationSuccess(true);
      await UserService.setTotpStatus();
    } else {
      setAuthenticationData({
        ...authenticationData,
        errorMessage:
          onBoardingMessages.AUTHENTICATION_SETUP_FORM.VERIFICATION_FAILED,
      });
      setIsButtonDisabled(true);
    }
  };

  useEffect(() => {
    const requiredStep = source === 'okta' ? 0 : 1;
    if (currentStep === requiredStep) {
      handleUserInformationValidation();
    }
  }, [userInformationDetails, source]);

  return {
    currentStep,
    onBackClick,
    onNextClick,
    isButtonDisabled,
    handleInformationTextFieldChange,
    handleLogoChange,
    handlePhoneCodeChange,
    handleRemoveImage,
    handleUserInformationValidation,
    userInformationDetails,
    passwordFormDetails,
    handlePasswordTextFieldChange,
    passwordTooltipParams,
    handlePasswordFormValidation,
    passwordErrorMessages,
    newPasswordErrorMessage,
    authenticationData,
    handleChangeVerificationCode,
    handleVerifyTotpEnrolment,
  };
};

export default useEndUserOnboardingPageData;
