import { Box } from '@mui/material';
import FloatingLocalDeviceMenu from '@src/_components/atoms/FloatingLocalDeviceMenu';
import FloatingVideoConfMenu from '@src/_components/atoms/FloatingVideoConfMenu';
import SettingsService from '@src/api-service/settings-service/SettingsService';
import React, { useEffect, useState } from 'react';
import theme from '../../../core-utils/theme';
import MainFooter from '../../molecules/MainFooter';
import ScannerUloadModal from '@src/_components/organisms/ScannerUpload';
import PolicyService from '@src/api-service/policy-service/PolicyService';

interface IHomeTemplate {
  leftNavComponent?: any;
  header?: any;
  content?: any;
}

const HomeTemplate: React.FC<IHomeTemplate> = ({
  leftNavComponent,
  header,
  content,
}) => {
  const [showFloatingMenu, setShowFloatingMenu] = useState<boolean>(false);
  const [showScannerUploadModal, setShowScannerUploadModal] = useState<boolean>(false);
  const [showScannerMenu, setShowScannerMenu] = useState<boolean>(false);
  const [isWindows, setIsWindows] = useState<boolean>(false);

  useEffect(() => {
    SettingsService.getCollabToolsSettings()
      .then((resp) => {
        if (resp && resp?.resourceConnectionString) {
          setShowFloatingMenu(true);
        }
      });
    PolicyService.isScannerAllowedByPolicy()
      .then((resp) => {
        if (resp?.decision) {
          setShowScannerMenu(true);
        }
      })
  }, []);


  useEffect(() => {
    const checkPlatform = () => {
      const platform = navigator.platform.toLowerCase();
      setIsWindows(platform.includes('win'));
    };

    checkPlatform();
  }, []);


  return (
    <Box height="100%">
      {header}
      <Box
        display="flex"
        overflow="overlay"
        position="absolute"
        width="100%"
        height={`calc(100% - ${theme.spacing(16.25)})`}
      >
        <Box>{leftNavComponent}</Box>
        <Box id="box" display="flex" flexDirection="column" width="100%">
          <Box
            sx={{
              width: '100%',
              flex: '1 0 auto',
            }}
          >
            {content}
            {(isWindows && showScannerMenu) ? <ScannerUloadModal isOpen={showScannerUploadModal} onClose={() => setShowScannerUploadModal(false)} /> : null}
          </Box>
          <Box flexShrink={0} width="100%">
            <MainFooter
              versionRequired={true}
              logoRequired={false}
            ></MainFooter>
          </Box>
        </Box>
      </Box>
      {showFloatingMenu ? <FloatingVideoConfMenu /> : null}
      {(isWindows && showScannerMenu) ? <FloatingLocalDeviceMenu onClick={() => setShowScannerUploadModal(true)} /> : null}
    </Box>
  );
};

export default HomeTemplate;
