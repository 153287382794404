import Config from '../../../config/config';
import {
  IAdministratorNotificationsDetail,
  ILogServerResponse,
  ILogSettingsResponse
} from '../../core-utils/constants';
import {
  MOCK_LOG_SERVERS,
  MOCK_LOG_SETTING_DATA
} from '../../core-utils/test-constants';
import axios from '../axios';
import UserService, {
  IListUserResponse,
  IListUsersResponse,
} from '../user-service/UserService';

const getSettingsURL = () => {
  return `${Config.BACKEND_BASE_URL}api/v1/settings/`;
  //return 'http://localhost:3004/';
};

const getThickClientUrl = () => `${Config.BACKEND_BASE_URL}api/thickclient`;
export interface ISessionRecordingSettingsResponse {
  administratorUserSessionsEnabled: boolean;
  saveCredsAllowed: boolean;
  userSessionsPerPage: number | null;
  authenticationTimeout: number | null;
  rotationPeriod: {
    time: number | null;
    diskSpace: number | null;
  };
  retentionPeriod: '90' | '365';
}

export interface INotificationSettingsResponse {
  administratorNotifications: Array<IAdministratorNotificationsDetail>;
  userNotificationsEnabled: boolean;
}

const SettingsService = {
  getAccountSettings: async () => {
    try {
      //return SAMPLE_APPLICATION_DROPDOWN;
      const accountSettingsResponse = await axios.get(
        getSettingsURL() + 'account',
      );
      return accountSettingsResponse.data;
    } catch (error) {
      return error;
    }
  },
  getOrgSettings: async () => {
    try {
      const resp = await axios.get(
        `${Config.DASHBOARD_API_URL}settings/org`,
      );
      return resp.data;
    } catch (error) {
      return error;
    }
  },
  updateAccountSettings: async (accountSettings: any) => {
    try {
      const accountSettingsResponse = await axios.put(
        getSettingsURL() + 'account',
        accountSettings,
      );
      return accountSettingsResponse.data;
    } catch (error) {
      throw error;
    }
  },
  getUserSessionsSettings: async () => {
    try {
      //return SESSION_RECORDING_SETTINGS_MOCK_DATA;
      const userSessionsSettingsResponse = await axios.get(
        getSettingsURL() + 'userSessions',
      );
      return userSessionsSettingsResponse.data;
    } catch (error) {
      return {};
      //return error;
    }
  },
  updateUserSessionsSettings: async (userSessionsSettings: any) => {
    try {
      const userSessionsSettingsResponse = await axios.put(
        getSettingsURL() + 'userSessions',
        userSessionsSettings,
      );
      return userSessionsSettingsResponse.data;
    } catch (error) {
      return {};
      //return error;
    }
  },
  getLogsSettings: async () => {
    try {
      //return SAMPLE_APPLICATION_DROPDOWN;
      const logSettingsResponse = await axios.get(getSettingsURL() + 'logs');
      return logSettingsResponse.data;
    } catch (error) {
      return error;
    }
  },
  updateLogsSettings: async (logSettings: any) => {
    try {
      const logSettingsResponse = await axios.put(
        getSettingsURL() + 'logs',
        logSettings,
      );
      return logSettingsResponse.data;
    } catch (error) {
      return error;
    }
  },
  getLogSettingsData: async (): Promise<{
    logSettingsData: ILogSettingsResponse;
    logServers: Array<ILogServerResponse>;
  }> => {
    return {
      logSettingsData: MOCK_LOG_SETTING_DATA,
      logServers: MOCK_LOG_SERVERS,
    };
  },

  updateLogSettingsData: async (logSettingsData: ILogSettingsResponse) => {
    //TODO : Implement API calls to update logSettings
  },
  updateLogServersData: async (logServers: Array<ILogServerResponse>) => {
    //TODO : Implement API calls to update logServers
  },

  updateLocalCredentialManagement: async (payload: any) => {
    // TODO : Update the payload type and calls when we get the api design
    await axios.post(getSettingsURL() + 'identityManagement', payload);
  },
  // Authentication settings API

  getAuthenticationSettings: async () => {
    try {
      //return SAMPLE_APPLICATION_DROPDOWN;
      const authenticationSettingsResponse = await axios.get(
        getSettingsURL() + 'authentication',
      );
      return authenticationSettingsResponse.data;
    } catch (error) {
      return error;
    }
  },

  getIdpList: async () => {
    try {
      const authenticationSettingsResponse = await axios.get(
        getSettingsURL() + 'idpList',
      );
      return authenticationSettingsResponse.data;
    } catch (error) {
      return error;
    }
  },

  updateAuthenticationSettings: async (authenticationSettings: any) => {
    try {
      const authenticationSettingsResponse = await axios.put(
        getSettingsURL() + 'authentication',
        authenticationSettings,
      );
      return authenticationSettingsResponse.data;
    } catch (error) {
      throw error;
    }
  },
  getCollabToolsSettings: async () => {
    try {
      const collabToolsSettingsResponse = await axios.get(
        getSettingsURL() + 'collabTools',
      );
      return collabToolsSettingsResponse.data;
    } catch (error) {
      return error;
    }
  },
  updateCollabToolsSettings: async (collabToolsSettings: any) => {
    try {
      const collabToolsSettingsResponse = await axios.post(
        getSettingsURL() + 'collabTools',
        collabToolsSettings,
      );
      return collabToolsSettingsResponse.data;

    } catch (error) {
      return error;
    }
  },
  unlinkIdp: async (payload: any) => {
    try {
      const response = await axios.delete(
        getSettingsURL() + 'sync',
        { data: payload }
      );
      return response?.data;
    } catch (error) {
      return { error };
    }
  },
  syncIDP: async (payload: any) => {
    return await axios.post(getSettingsURL() + 'sync', payload);
  },
  getNotificationsSettings: async () => {
    try {
      const notificationSettingsResponse = await axios.get(
        `${Config.DASHBOARD_API_URL}settings/notification`,
      );
      return notificationSettingsResponse.data;
    } catch (error) {
      console.error(error);
      return {};
    }
  },
  updateNotificationsSettings: async (notificationSettings: any) => {
    try {
      const notificationSettingsResponse = await axios.put(
        `${Config.DASHBOARD_API_URL}settings/notification`,
        notificationSettings,
      );
      return notificationSettingsResponse.data;
    } catch (error) {
      return error;
    }
  },
  getListOfAdmins: async (): Promise<Array<IListUserResponse>> => {
    const adminUsers: IListUserResponse[] = [];
    await UserService.listUsers().then(async (listUsersResponse) => {
      const usersList = listUsersResponse as IListUsersResponse;
      const promises = Object.keys(usersList).map(async (user) => {
        const userId = usersList[user]['username'];
        const { systemPermissions } = await UserService.getEffectiveAppsOfUser(
          userId,
        );
        const role = systemPermissions?.includes('ADMINISTER')
          ? 'admin'
          : 'non-admin';
        if (role === 'admin') adminUsers.push(usersList[user]);
      });
      await Promise.all(promises);
    });
    return adminUsers;
  },

  getApiKey: async () => {
    return axios.get(`${getSettingsURL()}logs/key`);
  },

  putApiKey: async () => {
    return axios.put(`${getSettingsURL()}logs/key`, {});
  },

  deleteApiKey: async () => {
    return axios.delete(`${getSettingsURL()}logs/key`, {});
  },

  /**
   * 
   * ATTACH YOUR APIS here 
   */
  getPrivateDnsConfigurations: async () => {
    return axios.get(`${getSettingsURL()}network/dns`);
  },

  postPrivateDnsConfiguration: async (payload: any) => {
    return axios.post(`${getSettingsURL()}network/dns`, payload);
  },

  deletePrivateDnsConfiguration: async (uuid: String) => {
    return axios.delete(`${getSettingsURL()}network/dns/${uuid}`);
  },

  getAppHostVMs: async () => {
    return axios.get(`${getThickClientUrl()}`);
  },
  
  getAppHostVMStatus: async (vmId: string) => {
    return axios.get(`${getThickClientUrl()}/${vmId}`);
  },

  syncAppHostVM: async (vmId: string) => {
    return axios.put(`${getThickClientUrl()}/sync/${vmId}`)
  },

  startAppHostVM: async (vmId: string) => {
    return axios.put(`${getThickClientUrl()}/connect/${vmId}`)
  },

  postFileUpload: async (formData: any) => {
    return axios.post(`${Config.BACKEND_BASE_URL}api/v1/uploadScan`, formData);
  }


};

export default SettingsService;
