import BackArrowIcon from '@assets/back-arrow.svg';
import deleteIcon from '@assets/delete-red.svg';
import { Avatars } from '@atoms/Avatar';
import Button from '@atoms/Button';
import IconFromSvg from '@atoms/IconFromSvg';
import { Box, Grid, Typography, debounce } from '@mui/material';
import SonetVideoPlayer from "@src/_components/atoms/VideoPlayer";
import ConfirmPopUp from '@src/_components/molecules/ConfirmPopUp';
import SessionRecordingSummaryCard from '@src/_components/molecules/SessionRecordingSummaryCard';
import PolicyViolationCards from '@src/_components/organisms/PolicyViolationCards';
import SessionRecordingPlayer from "@src/_components/organisms/SessionRecordingPlayer";
import SessionRecordingService from '@src/api-service/session-recording-service/sessionRecordingService';
import { default as settingsMessages } from '@src/core-utils/messages/settings';
import { getAvatarName } from '@utils/Helper/helper';
import sessionRecordingMessages from '@utils/messages/sessionRecordings';
import theme, { EXTRA_COLORS } from '@utils/theme';
import React, { useState } from "react";

/**
 * 
 *  NOTE: remove guacamole-common.js once we completely move out to mp4 files
 * 
 */

const SessionDetails = ({ handleBack, user, location, sessionHistoryDetails, summary, violations, showMp4Player = false }: any) => {
  const [duration, setDuration] = useState(0);
  const [seekPosition, setSeekPosition] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [hasSessonRecordingVideo, setHasSessonRecordingVideo] = useState(!!sessionHistoryDetails?.recordingIdentifier);

  const getPolicyViolationsData = () => {
    let policyViolations: any = [];
    violations?.forEach((violation: any, index: number) => {
      policyViolations.push({
        id: violation.offset.toString(),
        name: violation.category,
        description: violation.reason + '(' + violation.details + ')',
        actions: [],
        offset: violation.offset,
        index
      });
    });
    return policyViolations;
  };


  const getMarksData = () => {
    let marks: any = [];
    violations?.forEach((violation: any) => {
      marks.push({
        header: violation.category,
        description: violation.reason + '(' + violation.details + ')',
        type: 'high',
        time: violation.offset,
      });
    });
    return marks;
  };

  const getMarkersForMp4Player = () => {
    const marks = getMarksData();
    return marks.map((m: any, index: number) => ({ ...m, time: Math.floor(m.time / 1000), id: index }));
  }


  const UserDetails = () => (
    <Grid item container direction="row" columnGap={4} pt={1} flex={1} className="relative">
      <Grid item>
        <Avatars isImage={false} size={38} className="shadow-sm">
          {getAvatarName(user)}
        </Avatars>
      </Grid>
      <Box
        display="flex"
        flexDirection="column"
        rowGap={1}
        justifyContent="center"
      >
        <Typography variant="body1" color={theme.palette.primary.main}>
          {user}
        </Typography>
        {location && (
          <Typography
            variant="caption"
            color={theme.palette.text.disabled}
            paddingTop={1}
          >
            {location}
          </Typography>
        )}
      </Box>
      {
        hasSessonRecordingVideo
          ? <div className="sm:absolute sm:inset-y-0 sm:right-0 btn-primary group py-2 mx-0 sm:mx-auto sm:py-2.5" title={sessionRecordingMessages.DELETE.DELETE_SESSION_RECORDING}>
            <svg className="w-6 h-6 text-gray-300 hover:text-red-500 hover:animate-wiggle transition-all transform duration-300 ease-in-out" onClick={() => setShowConfirmModal(true)} fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" stroke-width="1" stroke="currentColor" />
            </svg>
          </div>
          : null
      }
    </Grid>
  );

  const BreadCrumbs = () => (
    <Grid item>
      <Button
        variant="text"
        startIcon={<IconFromSvg path={BackArrowIcon} alt="back" />}
        sx={{ color: theme.palette.text.secondary }}
        onClick={handleBack}
      >
        {sessionRecordingMessages.SESSION_RECORDING_PAGE.HEADING}
      </Button>
    </Grid>
  );

  const SessionPlayer = () => (
    <div className="w-full my-2 overflow-hidden rounded-lg shadow-sm transition-all duration-300 ease-in-out py-2">
      {
        sessionHistoryDetails.recordingExists ? (
          <SessionRecordingPlayer
            filePath={sessionHistoryDetails.recordingURL}
            marks={getMarksData()}
            onDurationChanged={(millis) => {
              setDuration(millis);
            }}
            duration={sessionHistoryDetails.duration}
          />
        ) : (
          <SonetVideoPlayer
            timeStart={seekPosition}
            url={`/sonet/api/sonet/rec/mp4/${sessionHistoryDetails.recordingIdentifier}.m4v`}
            markers={getMarkersForMp4Player()}
          />
        )
      }
    </div>
  );


  const SessionSummary = () => {
    return (
      <div className="w-full my-2 max-w-4xl overflow-hidden">
        <div className="flex flex-col px-4 pb-4 relative">
          <UserDetails />
          <div className="absolute inset-x-0 -bottom-4">
            {successMessage
              ? <Typography
                variant="body1"
                color={theme.palette.success.main}
                className="w-full text-center my-2 animate-fadeOut"
              >
                {successMessage}
              </Typography>
              : null
            }
            {errorMessage
              ? <Typography
                variant="body1"
                color={theme.palette.error.main}
                className="w-full text-center my-2 animate-fadeOut"
              >
                {errorMessage}
              </Typography>
              : null}
          </div>
        </div>
        <SessionRecordingSummaryCard {...summary} />
      </div>
    )
  }

  const isGuacPlayer = () => sessionHistoryDetails.recordingExists || false;

  const ViolationsSummary = () => (
    <div className="w-full my-2 max-w-4xl overflow-hidden">
      <Box display="flex" flexDirection="column" rowGap={2}>
        {violations.length !== 0 ? (
          <PolicyViolationCards
            violationsData={getPolicyViolationsData()}
            onViolationClick={(t: number) => {
              if (isGuacPlayer()) {
                setSeekPosition(t)
              } else {
                setSeekPosition(Math.floor(t / 1000))
              }
            }}
          />
        ) : (
          <Box
            className="h-16 mt-2 rounded-lg bg-white shadow-sm flex justify-center items-center"
          >
            <Typography
              variant="body2"
              color={theme.palette.text.secondary}
            >
              {
                sessionRecordingMessages.SESSION_RECORDING_PAGE
                  .NO_POLICIES
              }
            </Typography>
          </Box>
        )}
      </Box>
    </div>
  )


  const handleDeleteRecordingRequest = (historyID: number) => {
    setIsDeleting(true);
    SessionRecordingService.deleteSessionRecording(`${historyID}`)
      .then((resp) => {
        setHasSessonRecordingVideo(false);
        setSuccessMessage(sessionRecordingMessages.DELETE.SUCCESS_MESSAGE);
        setTimeout(() => { setSuccessMessage("") }, 3000);
      })
      .catch((err) => {
        console.error("Unable to delete recording:", err);
        setErrorMessage(sessionRecordingMessages.DELETE.ERROR_MESSAGE);
        setTimeout(() => { setErrorMessage("") }, 3000);
      })
      .finally(() => {
        setIsDeleting(false)
      });
  }

  return (
    <Grid
      container
      direction="column"
      sx={{
        width: '100%',
        paddingLeft: theme.spacing(25),
        paddingRight: theme.spacing(25),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(8),
        backgroundColor: EXTRA_COLORS.bluishBackground,
      }}
    >
      <BreadCrumbs />
      <div className="flex justify-center">
        <div className="w-full max-w-4xl flex flex-col justify-center mt-2">
          {hasSessonRecordingVideo ? <SessionPlayer /> : null}
          <SessionSummary />
          <ViolationsSummary />
          <ConfirmPopUp
            open={showConfirmModal}
            content={sessionRecordingMessages.DELETE.CONFIRM_DELETE_MESSAGE}
            headingText={sessionRecordingMessages.DELETE.DELETE_SESSION_RECORDING}
            handleClose={() => setShowConfirmModal(false)}
            onButtonClick={debounce(() => { handleDeleteRecordingRequest(sessionHistoryDetails?.recordingIdentifier) }, 1000)}
            buttonText={sessionRecordingMessages.DELETE.ACTION}
          />
        </div>
      </div>
    </Grid>
  )
}

export default SessionDetails;

